<template>
  <div>
    <b-row class="m-0 main-section mb-1">
      <b-col cols="12">
        <div class="d-block d-md-flex justify-content-between align-items-center">
          <div class="mb-2 m-md-0">
            <h2>Recommendation</h2>
            <p class="mb-0">Recommendation insights and history</p>
          </div>
          <div class="d-block d-sm-flex align-items-center">
            <b-button class="profile-btn mb-2 my-sm-0" variant="primary" v-b-modal.modal-no-backdrop>
              <feather-icon
                class="mr-1"
                icon="PlusIcon"
                fill="#fff"
                stroke="#fff"
                size="18"
              />
              Create Recommendation
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
    <recommendation-model />
  </div>
</template>
<script>
import RecommendationModel from '../RecommendationModel.vue'

export default {
  components: {
    RecommendationModel
  },
};
</script>

<style lang="scss" >
</style>
