<template>
    <div>
      <b-modal id="modal-update-status" title="BootstrapVue" ref="updateStatusModal" @hide="onStatusModalClose()">
        <template #modal-header="">
          <div class="d-flex align-items-center">
            <h2 class="mb-0">Update Recommendation</h2>
          </div>

        </template>
        <div>
          <validation-observer ref="simpleRules">
            <div class="mx-2 pt-2">
              <b-row class="m-0">
                <b-col sm="12" md="12">
                  <div>You're updating: <strong>{{ recommendationData && recommendationData.symbol }}</strong></div>
                </b-col>
                <b-col sm="12" md="8" class="d-flex align-items-center radio-input">
                  <validation-provider
                    :rules="{ required: true }"
                    :name="'radio-validation'"
                    v-slot="validationContext"
                  >
                    <div class="d-flex mb-1">
                      <div class="">
                        <b-form-radio
                          v-model="formData.status"
                          :name="`status`"
                          :class="{ 'is-invalid': formSubmitted && !validationContext.valid }"
                          value="is_follow_up"
                          >Follow-up update</b-form-radio
                        >
                      </div>
                      <div class="ml-2">
                        <b-form-radio
                          v-model="formData.status"
                          :name="`status`"
                          :class="{ 'is-invalid': formSubmitted && !validationContext.valid }"
                          value="is_close"
                          >Close recommendation</b-form-radio
                        >
                        {{ validationContext.error }}
                      </div>
                    </div>
                    <b-form-invalid-feedback :id="'radio-validation'" v-if="formSubmitted && !validationContext.valid">{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
                <b-col sm="12" md="4" v-if="formData.status === 'is_close'">
                  <SelectField
                    :id="'Close-Reason'"
                    :placeholder="''"
                    :describedby="'close-reason-feedback'"
                    :label="'Close Reason'"
                    :name="'Close Reason'"
                    :valueField="'value'"
                    :textField="'text'"
                    :rules="{ required: true }"
                    :modelValue="formData.closeReason"
                    @input="(val) => (formData.closeReason = val)"
                    :options="closeReasonOptions"
                    :showValidationFeedback="formSubmitted"
                    :showValidationFeedbackmes="formSubmitted"
                  />
                </b-col>
                <b-col sm="12" md="8">
                  <InputField
                    :id="'Remark'"
                    :placeholder="''"
                    :describedby="'Remark-feedback'"
                    :label="'Remark'"
                    :name="'Remark'"
                    :rules="{}"
                    :modelValue="formData.remark"
                    @input="(val) => (formData.remark = val)"
                    :showValidationFeedback="formSubmitted"
                    :showValidationFeedbackmes="formSubmitted"
                    />
                </b-col>
                <b-col sm="12" md="4" v-if="formData.closeReason == 'TARGET_MISS' || formData.closeReason == 'EXIT'">
                  <InputField
                    :id="'Exit-Market-Price'"
                    type="number"
                    :placeholder="''"
                    :describedby="'Exit-Market-Price-feedback'"
                    :label="'Exit Market Price'"
                    :name="'Exit Market Price'"
                    :rules="{ required: true }"
                    :modelValue="formData.exitPrice"
                    @input="(val) => (formData.exitPrice = val)"
                    :showValidationFeedback="formSubmitted"
                    :showValidationFeedbackmes="formSubmitted"
                    />
                </b-col>
              </b-row>
            </div>
          </validation-observer>
        </div>
        <template #modal-footer="{ cancel }">
          <b-button size="md" class="cancel" @click="cancel()"> Cancel </b-button>
          <b-button size="md" variant="primary" @click="updateStatus(recommendationData.id)"> Publish </b-button>
        </template>
      </b-modal>
    </div>
</template>



<script>
import { ref, watch, onMounted, inject, reactive } from "@vue/composition-api";
import InputField from "@/views/components/form/InputField.vue";
import SelectField from "@/views/components/form/SelectField.vue";
import DateField from "@/views/components/form/DateField.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { v4 as uuidv4 } from 'uuid';
import Vue from 'vue'

/* eslint-disable global-require */
export default {
  components: {
    SelectField,
    InputField,
    DateField,
  },
  props: {
    recommendationData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  setup(props) {
    const $http = inject("$http");

    const updateStatusModal = ref(null);
    const simpleRules = ref("");
    const formSubmitted= ref(false)
    const formData = reactive({
      // status: "",
      closeReason: "",
      exitPrice: "",
      remark: ""
    });

    const closeReasonOptions = [
      { value: null, text: 'Select reason', disabled: true },
      { value: 'TARGET_HIT', text: 'TARGET_HIT' },
      { value: 'TARGET_MISS', text: 'TARGET_MISS' },
      { value: 'STOPLOSS_TRIGGER', text: 'STOPLOSS_TRIGGER' },
      { value: 'EXIT', text: 'EXIT' }
    ]

    const onStatusModalClose = () => {
      formData.status = ""
      formData.closeReason = ""
      formData.exitPrice = ""
      formData.remark = ""
    }

    const updateStatus = (id) => {
      formSubmitted.value =true
      simpleRules.value.validate().then(async (success) => {
        if (success) {
          console.log('success', formData);
          const dataPayload = {
            msgID: uuidv4(), 
            id: id,
            isFollowUp: formData.status == 'is_follow_up' ? true : false,
            isClosing: formData.status == 'is_close' ? true : false,
            remark: formData.remark
          }
          if(formData.status == 'is_close') {
            dataPayload.exitPrice = parseFloat(formData.exitPrice)
            dataPayload.closeReason = formData.closeReason
          }
          
          await $http
            .put(`/advisor/recommendations/update`, dataPayload)
            .then((response) => {
              console.log('response -->', response);
              
              formSubmitted.value =false
              if(response.status == 204) {
                updateStatusModal.value.hide()
                Vue.$toast({
                  component: ToastificationContent,
                  props: {
                    title: `Success`,
                    icon: "AlertCircleIcon",
                    variant: "success",
                    text:'Recommendations updated successfully!',
                    closeOnClick: false,
                    timeout: false,
                  },
                });
              } else {
                Vue.$toast({
                  component: ToastificationContent,
                  props: {
                    title: `Error`,
                    icon: "AlertCircleIcon",
                    variant: "danger",
                    text: "Something want to wrong",
                    closeOnClick: false,
                    timeout: false,
                  },
                });
              }
            })
            .catch((error) => {
              console.log('error -->', error);
              formSubmitted.value =false
              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: `Error`,
                  icon: "AlertCircleIcon",
                  variant: "danger",
                  text: error.response,
                  closeOnClick: false,
                  timeout: false,
                },
              });
            });
        }
      });
    }

    return {
      onStatusModalClose,
      updateStatusModal,
      updateStatus,
      simpleRules,
      formData,
      formSubmitted,
      closeReasonOptions
    };
  },
};
</script>

<style lang="scss">

#modal-no-backdrop .nav-item {
  text-transform: capitalize;
}

#modal-no-backdrop {
  .modal-header:has(.buy),
  .modal-footer:has(.buy) {
    background: rgba(39, 188, 148, 0.12);
  }

  .modal-header:has(.sell),
  .modal-footer:has(.sell) {
    background: rgba(235, 37, 37, 0.12);
  }

  .modal-header:has(.hold),
  .modal-footer:has(.hold) {
    background: rgba(37, 99, 235, 0.12);
  }
  .modal-body {
    padding: 0 !important;
  }

  .modal-footer:has(.buy) .publish {
    background: #27bc94 !important;
  }

  .modal-footer:has(.sell) .publish {
    background: #fe5722 !important;
  }

  .modal-footer:has(.hold) .publish {
    background: #2563eb !important;
  }
  .modal-footer .cancel {
    background: #c4c4c4 !important;
  }
  .modal-footer button {
    color: #fff !important;
    border: 0px !important;
    padding: 10px 20px;
    font-weight: 700;
  }
  .add-modal ul {
    background: rgba(248, 248, 248, 1);
    margin-left: 0px !important;
    padding-left: 16px;
    margin-bottom: 0px !important;
  }
  .nav-link {
    padding: 1.61rem 5rem !important;
    font-weight: 700 !important;
    &.active {
      &.buy {
        color: #27bc94 !important;
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 3px;
          transition: transform 0.3s;
          background: #27bc94 !important;
        }
      }

      &.sell {
        color: #fe5722 !important;
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 3px;
          transition: transform 0.3s;
          background: #fe5722 !important;
        }
      }

      &.hold {
        color: #2563eb !important;
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 3px;
          transition: transform 0.3s;
          background: #2563eb !important;
        }
      }
    }
    &:after {
      background: transparent !important;
    }
  }
  .radio-button {
    margin: 0;
    margin-right: auto;
  }
}

.form-control.is-valid {
  background-image: none;
}
.custom-select.is-valid {
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23d8d6de' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 1rem center/10px 10px no-repeat
}
</style>