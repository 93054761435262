
<template>
  <div>
    <b-row class="m-0">
      <b-col cols="12 mb-4">
        <b-row class="m-0 details">
          <b-col class="col-4 pb-2 px-5" v-for="(rec, index) in recommendationCard" :key="index">
            <div class="d-flex justify-content-between count">
              <p class="header">{{ rec.title }}</p>
              
            </div>
            <div class="d-flex justify-content-between count">
              <span>Total</span>
              <span >{{ rec.total }}
              </span>
            </div>
            <div class="d-flex justify-content-between count">
              <span>Success</span>
              <span class="amount">{{ rec.hit }}<small>({{ rec.hitPercentage }}%)</small>
                <feather-icon
                  class="ml-1"
                  icon="CheckIcon"
                  stroke="#2a9d8f"
                  size="18"
                />
              </span>
            </div>
            <div class="d-flex justify-content-between count">
              <span>Failed</span>
              <span class="loss-amount">{{ rec.miss }}<small>({{ rec.missPercentage }}%)</small>
                <feather-icon
                  class="ml-1"
                  icon="XIcon"
                  stroke="#fa5150"
                  size="18"
                />
              </span>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12 mb-4">
        <b-card class="card table-card">
          <b-card-header class="h1">
            <b-card-title class="font-weight-bolder h1">All Recommendation</b-card-title>
          </b-card-header>

          <b-table
            sticky-header
            responsive
            class="position-relative b-table-sticky-table"
            :items="recommendations"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            sort-direction="asc"
            show-empty
            @sort-changed="sortChanged"
            :per-page="parPage"
            :current-page="currentPage"
            :filter="filter"
            :filter-included-fields="[
              'date',
              'symbol',
              'rating',
              'entryPrice',
              'targetPrice',
              'status',
            ]"
          >
            <!-- Loader -->
            <template #table-busy>
              <div class="position-relative load-data py-2">
                <!-- <loader /> -->
              </div>
            </template>

            <!-- empty data -->
            <template #empty>
              <p class="text-center pt-1">Data Not found.</p>
            </template>

            <!-- Column: createdAt -->
            <template #cell(createdAt)="data">
              {{ getDate(data.item.createdAt) }}
            </template>

            <template #cell(gainPercent)="data">
              {{data.item.gainPercent ? `${data.item.gainPercent}%` : '-'}}
            </template>

            <template #cell(status)="data">
              <b-badge
                pill
                :variant="
                  data.item.status == 'created' ? 'light-primary' : data.item.status == 'target_hit' ? 'light-success' : data.item.status == 'target_miss' ? 'light-danger' : data.item.status == 'target_open' ? 'light-primary' : 'light-secondary'
                "
                class="text-capitalize active-status"
              >
              {{ data.item.status == 'created' ? 'Unknown' : data.item.status == 'target_hit' ? 'Success' : data.item.status == 'target_miss' ? 'Missed' : data.item.status == 'target_open' ? 'Open' : "null" }}
              </b-badge>
            </template>

            <!-- Column: Rating -->
            <template #cell(callType)="data">
              <b-badge
                pill
                :variant="
                  data.item.callType == 'buy' ? 'light-success' : 'light-danger'
                "
                class="text-capitalize active-status"
              >
                {{ data.item.callType }}
              </b-badge>
            </template>

            <template #cell(entryPrice)="data">
              {{ data.item.entryPrice.toFixed(2) }}
            </template>

            <template #cell(recommendationPrice)="data">
              {{ data.item.recommendationPrice.toFixed(2) }}
            </template>


            <!-- Column: details -->
            <template #cell(details)="data">
              <b-button
                @click="recommendationData = data.item"
                v-b-modal.modal-view-details
                variant="outline-primary rounded-pill"
              >
                View
              </b-button>
              <b-button size="md" v-if="data.item.status == 'target_open'" @click="recommendationData = data.item" variant="outline-primary rounded-pill" class="ml-1" v-b-modal.modal-update-status> Update </b-button>
            </template>
          </b-table>

          <b-card-body class="d-flex justify-content-center flex-wrap pt-0">
            <!-- pagination -->
            <div class="w-100">
              <b-pagination
                v-model="currentPage"
                :total-rows="recommendations.length"
                :per-page="parPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon icon="ArrowLeftIcon" size="18" class="mr-1" /> Previous
                </template>
                <template #next-text>
                  <span>Next</span> <feather-icon icon="ArrowRightIcon" size="18" class="ml-1"/> 
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </b-card>

        
      </b-col>
    </b-row>

    <recommendation-view-model v-if="recommendationData" :recommendationData="recommendationData" />
    <recommendation-update-model v-if="recommendationData" :recommendationData="recommendationData" />
  </div>
</template>
    
<script>
import { ref, onMounted, inject, reactive } from "@vue/composition-api";
import InputField from "@/views/components/form/InputField.vue";
import SelectField from "@/views/components/form/SelectField.vue";
import DateField from "@/views/components/form/DateField.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import Vue from 'vue'
import RecommendationViewModel from "../RecommendationViewModel.vue";
import RecommendationUpdateModel from "../RecommendationUpdateModel.vue";

export default {
  components: {
    SelectField,
    InputField,
    DateField,
    RecommendationViewModel,
    RecommendationUpdateModel
  },
  setup() {

    const $http = inject("$http");
    const parPage = 15;
    const currentPage = ref(1);
    const sortByAscDesc = ref("asc");
    const sortBy = ref("");
    const filter = ref("");
    const sortDesc = ref(false);
    const fields = [
    {
        key: "createdAt",
        label: " Date",
        sortable: true,
      },
      {
        key: "symbol",
        label: "Symbol",
        sortable: true,
      },
      {
        key: "callType",
        label: "Rating",
        sortable: true,
      },
      {
        key: "entryPrice",
        label: "Entry Price",
        sortable: true,
        class: "",
      },
      {
        key: "targetPrice",
        label: "Target Price",
        sortable: true,
      },
      {
        key: "gainPercent",
        label: "Gain",
        sortable: true,
      },
      {
        key: "status",
        label: "Status",
        sortable: true,
      },
      {
        key: "details",
        label: "Details",
      },
    ];

    const recommendationCard = ref([]);

    const recommendationData = ref(null);
    let recommendations = ref([]);

    onMounted(async () => {
      await getRecommendation();
      recommendationCard.value = recommendationCard.value.map(card => {
        const { total, hit, miss } = card;
        card.hitPercentage = total ? ((hit / total) * 100).toFixed(2) : 0;
        card.missPercentage = total ? ((miss / total) * 100).toFixed(2) : 0;
        return card;
      });
    });

    const getRecommendation = async () => {
      await $http
        .get(`/advisor/recommendations`)
        .then((response) => {
          recommendations.value = response.data.recommendations;
          const summaryBySegment = response.data.summaryBySegment;
          if(response.data.summary) {
            recommendationCard.value.push({...response.data.summary, title: 'Summary' });
          } else {
            recommendationCard.value.push({ total: 0, hit: 0, miss: 0, open: 0, title: 'Summary' });
          }
          if(summaryBySegment) {
            recommendationCard.value.push({...summaryBySegment.EQUITY, title: 'Cash' });
            const F_and_O = {
              title: "F&O",
              total: summaryBySegment.OPTIONS.total + summaryBySegment.FUTURES.total,
              hit: summaryBySegment.OPTIONS.hit + summaryBySegment.FUTURES.hit,
              miss: summaryBySegment.OPTIONS.miss + summaryBySegment.FUTURES.miss,
              open: summaryBySegment.OPTIONS.open + summaryBySegment.FUTURES.open
            };
            recommendationCard.value.push(F_and_O);
          } else {
            recommendationCard.value.push({ total: 0, hit: 0, miss: 0, open: 0, title: 'Cash' });
            recommendationCard.value.push({ total: 0, hit: 0, miss: 0, open: 0, title: 'F&O' });
          }
        })
        .catch((error) => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: `Error`,
              icon: "AlertCircleIcon",
              variant: "danger",
              text: error.response,
              closeOnClick: false,
              timeout: false,
            },
          });
        });
    };
    const sortChanged = (e) => {
      console.log(e.sortBy, "e.sortBy");
      sortBy.value = e.sortBy;
      if (e.sortDesc == true) {
        sortByAscDesc.value = "desc";
      } else {
        sortByAscDesc.value = "asc";
      }
    };
    const getDate = (val) => {
        const date =new Date(val * 1000);
        return moment(date).format('DD-MM-YYYY')
    }

    const daysCalculator = (days) => {
      const years = Math.floor(days / 365);
      const remainingDays = days % 365;
      const months = Math.floor(remainingDays / 30);
      const remainingDaysAfterMonths = remainingDays % 30;
      let output = '';
      if (years > 0) output += ` ${years} year`;
      if (months > 0) output += ` ${months} month`;
      if (remainingDaysAfterMonths > 0) output += ` ${remainingDaysAfterMonths} days`;
      return output;
    }

    return {
      getRecommendation,
      sortByAscDesc,
      sortDesc,
      sortChanged,
      fields,
      filter,
      currentPage,
      parPage,
      sortBy,
      recommendationData,
      recommendations,
      getDate,
      daysCalculator,
      recommendationCard
      
    };
  },
};
</script>
  
<style lang="scss" >
.count {
  font-size: 18px;
  font-weight: 300;
  line-height: 25px;
  color: #000;
  .amount {
    color: #2a9d8f;
  }
  .loss-amount {
    color: #fa5150;
  }
}
.details {
  background: white;
  border-radius: 9.11px;
  color: #000;
  padding: 30px 60px;
}
.details .col-4:not(:last-child) {
  border-right: 1.14px solid #d0d2da;
}
.header {
  font-size: 17px;
  font-weight: 600;
  line-height: 21px;
}
.modal-footer .done {
  background: #2563eb !important;
}
.modal-footer .update-status {
  background: #27bc94 !important
}
.radio-input {
  min-height: 89px;
}
</style>

