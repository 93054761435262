<template>
    <div>
      <FirstSection />
      <div class="mx-2">
        <MainSection />
      </div>
    </div>
  </template>
  
  <script>
  import FirstSection from "./FirstSection.vue";
  import MainSection from "./MainSection.vue";
  
  export default {
    components: {
      FirstSection,
      MainSection,
    },
    setup(props) {
      return {};
    },
  };
  </script>