<template>
    <div>
      <b-modal id="modal-view-details" title="BootstrapVue">
          <template #modal-header="">
            <div class="d-flex align-items-center">

              <h2 class="mb-0">{{ recommendationData && recommendationData.symbol }}</h2>
              <p class="ml-1 mb-0">
                {{recommendationData && recommendationData.tradeSegment}}
              </p>
            </div>

          </template>
          <div v-if="recommendationData">
            <b-row>
              <b-col sm="12" md="4" class="white-space text-center">
                <h6 class="font-weight-bolder">Date</h6>
                <p>{{ getDate( recommendationData.createdAt) }}</p>
              </b-col>

              <b-col sm="12" md="4" class="white-space text-center">
                <h6 class="font-weight-bolder">Rating</h6>
                <b-badge
                  pill
                  :variant="recommendationData.callType == 'buy' ? 'light-success' : 'light-danger'"
                  class="text-capitalize active-status"
                >
                  {{ recommendationData.callType }}
                </b-badge>
              </b-col>

              <b-col sm="12" md="4" class="white-space text-center">
                <h6 class="font-weight-bolder">Recommendation Period</h6>
                <p>{{ recommendationData.tradePeriod }}</p>
              </b-col>

              <b-col sm="12" md="4" class="white-space text-center">
                <h6 class="font-weight-bolder">Status</h6>
                <b-badge
                  pill
                  :variant="
                    recommendationData.status == 'created' ? 'light-primary' : recommendationData.status == 'target_hit' ? 'light-success' : recommendationData.status == 'target_miss' ? 'light-danger' : recommendationData.status == 'target_open' ? 'light-primary' : 'light-secondary'
                  "
                  class="text-capitalize active-status"
                >
                  {{ recommendationData.status == 'created' ? 'Unknown' : recommendationData.status == 'target_hit' ? 'Success' : recommendationData.status == 'target_miss' ? 'Missed' : recommendationData.status == 'target_open' ? 'Open' : "null" }}
                </b-badge>
              </b-col>

              <b-col sm="12" md="4" class="white-space text-center">
                <h6 class="font-weight-bolder">Entry price</h6>
                <p>{{ recommendationData.entryPrice }}</p>
              </b-col>

              <b-col sm="12" md="4" class="white-space text-center">
                <h6 class="font-weight-bolder">Target Price</h6>
                <p>{{ recommendationData.targetPrice }}</p>
              </b-col>

              <b-col sm="12" md="4" class="white-space text-center">
                <h6 class="font-weight-bolder">Time To Target</h6>
                <p>{{recommendationData.targetTime}}</p>
              </b-col>

              <b-col sm="12" md="4" class="white-space text-center">
                <h6 class="font-weight-bolder">Stop Loss</h6>
                <p>{{ recommendationData.stoploss }}</p>
              </b-col>

              <b-col sm="12" md="4" class="white-space text-center">
                <h6 class="font-weight-bolder">Research report</h6>
                <a v-if="recommendationData.researchURL" class="text-truncate font-weight-bolder" :href="recommendationData.researchURL" target="_blank"> View Report </a>
                <p v-else>-</p>
              </b-col>

              <b-col sm="12" md="4" class="white-space text-center">
                <h6 class="font-weight-bolder">Gain Percent</h6>
                <p>{{recommendationData.gainPercent ? `${recommendationData.gainPercent}%` : '-'}}</p>
              </b-col>

              <b-col sm="12" md="4" class="white-space text-center">
                <h6 class="font-weight-bolder">Exit price</h6>
                <p>{{ recommendationData.closePrice ? recommendationData.closePrice : '-' }}</p>
              </b-col>

              <!-- <b-col sm="12" md="6" class="px-2">
                <h6 class="font-weight-bolder">Disclimer</h6>
                <p class="mb-0">
                  Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                  amet sint. Velit officia consequat duis enim
                </p>
              </b-col> -->
              <b-col sm="12" class="px-2">
                <h6 class="font-weight-bolder">Outlook</h6>
                <p class="mb-0">
                  {{ recommendationData.outlook }}
                </p>
              </b-col>
            </b-row>
          </div>
          <template #modal-footer="{ cancel }">
            <b-button size="md" class="done" @click="cancel()"> OK </b-button>
          </template>
        </b-modal>
    </div>
</template>



<script>
import { ref, watch, onMounted, inject, reactive } from "@vue/composition-api";
import InputField from "@/views/components/form/InputField.vue";
import SelectField from "@/views/components/form/SelectField.vue";
import DateField from "@/views/components/form/DateField.vue";
import moment from "moment";

/* eslint-disable global-require */
export default {
  components: {
    SelectField,
    InputField,
    DateField,
  },
  props: {
    recommendationData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  setup(props) {
    const $http = inject("$http");

    const getDate = (val) => {
      const date =new Date(val * 1000);
      return moment(date).format('DD-MM-YYYY')
    }

    return {
      getDate
    };
  },
};
</script>

<style lang="scss">

#modal-no-backdrop .nav-item {
  text-transform: capitalize;
}

#modal-no-backdrop {
  .modal-header:has(.buy),
  .modal-footer:has(.buy) {
    background: rgba(39, 188, 148, 0.12);
  }

  .modal-header:has(.sell),
  .modal-footer:has(.sell) {
    background: rgba(235, 37, 37, 0.12);
  }

  .modal-header:has(.hold),
  .modal-footer:has(.hold) {
    background: rgba(37, 99, 235, 0.12);
  }
  .modal-body {
    padding: 0 !important;
  }

  .modal-footer:has(.buy) .publish {
    background: #27bc94 !important;
  }

  .modal-footer:has(.sell) .publish {
    background: #fe5722 !important;
  }

  .modal-footer:has(.hold) .publish {
    background: #2563eb !important;
  }
  .modal-footer .cancel {
    background: #c4c4c4 !important;
  }
  .modal-footer button {
    color: #fff !important;
    border: 0px !important;
    padding: 10px 20px;
    font-weight: 700;
  }
  .add-modal ul {
    background: rgba(248, 248, 248, 1);
    margin-left: 0px !important;
    padding-left: 16px;
    margin-bottom: 0px !important;
  }
  .nav-link {
    padding: 1.61rem 5rem !important;
    font-weight: 700 !important;
    &.active {
      &.buy {
        color: #27bc94 !important;
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 3px;
          transition: transform 0.3s;
          background: #27bc94 !important;
        }
      }

      &.sell {
        color: #fe5722 !important;
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 3px;
          transition: transform 0.3s;
          background: #fe5722 !important;
        }
      }

      &.hold {
        color: #2563eb !important;
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 3px;
          transition: transform 0.3s;
          background: #2563eb !important;
        }
      }
    }
    &:after {
      background: transparent !important;
    }
  }
  .radio-button {
    margin: 0;
    margin-right: auto;
  }
}

.form-control.is-valid {
  background-image: none;
}
.custom-select.is-valid {
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23d8d6de' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 1rem center/10px 10px no-repeat
}
</style>